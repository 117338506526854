$primary: #db0032;
$secondary: #333f48;

$colors: (
  primary: $primary,
  secondary: $secondary,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
