/* Archivo fonts */
@font-face {
  font-family: 'Archivo';
  src: url('./Archivo/Archivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('./Archivo/Archivo-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Archivo';
  src: url('./Archivo/Archivo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
