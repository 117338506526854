@import '../fonts/fonts.css';

$font-family-base: 'Archivo', sans-serif;

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$colors: map-remove(
  $colors,
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'white',
  'gray',
  'gray-dark'
);

$grays: map-remove(
  $grays,
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900'
);

$theme-colors: map-remove(
  $theme-colors,
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark'
);

@import 'variables';
@import 'custom';
