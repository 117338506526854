.btn-primary {
  @include button-variant($primary, $primary);
}
.btn-secondary {
  @include button-variant($secondary, $secondary);
}

// hides scrollbar
::-webkit-scrollbar {
  display: none;
}

body {
  background-color: $secondary;
}

h1,
th {
  font-family: 'Roboto Slab', serif;
  color: #ffffff;
}

.hiddenDisplay {
  opacity: 0;
}

.biggerFont {
  font-size: 24px;
}

.display {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar.gray {
  background-color: $primary;
}

.imgCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex-grow: 1;
}

.navRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* CalculationGraph */
.sales {
  overflow: hidden;
  .title {
    margin-left: 32px;
    font-size: 16px;
  }
}

.radiusdot {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}

.legend {
  text-align: right;
  color: #999;
  font-size: 14px;

  li {
    height: 48px;
    line-height: 48px;
    display: inline-block;

    & + li {
      margin-left: 24px;
    }
  }
}

/* Card */
.card-stats .card-body {
  padding: 15px 15px 0px;
}

.card-stats .numbers p {
  margin-bottom: 0;
}

.card-stats {
  padding: 0px 15px 15px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.flex-r {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.align-r {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 840px) {
  .navRow {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .align-r {
    align-items: center;
  }
  .flex-r {
    flex-direction: column;
  }
}
